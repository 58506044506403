import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

const typography = new Typography({
  googleFonts: [
    {
      name: "Catamaran",
      styles: ["900"],
    },
    {
      name: "Open Sans",
      styles: ["400", "400i", "700", "700i"],
    },
  ],

  baseFontSize: "16px",
  baseLineHeight: 1.75,
  scaleRatio: 2.25, // 5/2 old value
  headerFontFamily: ["Catamaran", "sans-serif"],
  bodyFontFamily: ["Open Sans", "sans-serif"],

  headerWeight: 900,
  bodyWeight: 400,
  boldWeight: 700,

  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    // h1: {
    //   fontFamily: ["Montserrat", "sans-serif"].join(","),
    // },
    // blockquote: {
    //   ...scale(1 / 5),
    //   color: gray(41),
    //   fontStyle: "italic",
    //   paddingLeft: rhythm(13 / 16),
    //   marginLeft: rhythm(-1),
    //   borderLeft: `${rhythm(3 / 16)} solid ${gray(10)}`,
    // },
    // "blockquote > :last-child": {
    //   marginBottom: 0,
    // },
    // "blockquote cite": {
    //   ...adjustFontSizeTo(options.baseFontSize),
    //   color: options.bodyColor,
    //   fontWeight: options.bodyWeight,
    // },
    // "blockquote cite:before": {
    //   content: '"— "',
    // },
    // ul: {
    //   listStyle: "disc",
    // },
    // "ul,ol": {
    //   marginLeft: 0,
    // },
    // [MOBILE_MEDIA_QUERY]: {
    //   "ul,ol": {
    //     marginLeft: rhythm(1),
    //   },
    //   blockquote: {
    //     marginLeft: rhythm(-3 / 4),
    //     marginRight: 0,
    //     paddingLeft: rhythm(9 / 16),
    //   },
    // },
    // "h1,h2,h3,h4,h5,h6": {
    //   marginTop: rhythm(2),
    // },
    li: {
      marginBottom: "0.5rem",
    },
    h4: {
      letterSpacing: "0.140625em",
      textTransform: "uppercase",
    },
    h6: {
      fontStyle: "italic",
    },
    a: {
      color: "#2790b0",
      textDecoration: "none",
      cursor: "pointer",
    },
    "a:hover,a:active": {
      boxShadow: "0 1px 0 0 currentColor",
    },
    "mark,ins": {
      background: "#007acc",
      color: "white",
      padding: `${rhythm(1 / 16)} ${rhythm(1 / 8)}`,
      textDecoration: "none",
    },
  }),
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
