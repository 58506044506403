import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import { Page } from "../../components/page"
import { PageLayoutParallax } from "../../components/layout"
import { ParallaxHeader } from "../../components/parallaxHeader"

// import SEO from "../../components/seo"
import insects from "./Insects.gif"

import styled from "styled-components"

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const Card = styled.div`
  //   cursor: pointer;
  flex: 1 1 180px;
  align-content: center;
`

const Training = ({ data, location }) => {
  const query = useStaticQuery(graphql`
    query TrainingQuery {
      cover: file(relativePath: { eq: "training/Maillard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      animation: file(relativePath: { eq: "training/Insects.gif" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Page location={location} title={"siteTitle"} transparentNav>
      <ParallaxHeader
        imgFluid={query.cover.childImageSharp.fluid}
        title="Training en educatie"
        imgMargin="-25vw"
      />
      <PageLayoutParallax>
        <p>
          Aardvark is altijd in beweging, op zoek naar nieuwe toepassingen en
          kan bogen op een schat aan werkervaring, onderzoeksmethoden en
          praktische oplossingen om te komen tot een beter product. Deze kennis
          wordt overgedragen via trainingen en gastcolleges op maat. Van
          basisschool tot universiteit, van korte workshop tot meerdaags
          inwerkprogramma van nieuwe werknemers.
        </p>
        <ul>
          <h4>Aardvark inspireert onder andere door:</h4>
          <li>
            Workshop/lezing of (gast)college over product/grondstof
            eigenschappen
          </li>
          <li>
            Workshop/lezing of (gast)college over levensmiddelen technologie, of
            een deelgebied binnen de levensmiddelen technologie
          </li>
          <li>
            Trainingen/inwerkprogramma’s over grondstoffen en hun
            gebruikseigenschappen (plakken, binden, smelten, etc.)
          </li>
        </ul>

        <img src={insects} />
      </PageLayoutParallax>
    </Page>
  )
}

export default Training
