import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import { Page } from "../../components/page"
import { PageLayout, RowFlex } from "../../components/layout"
import { SlideShow } from "../../components/slideShow"
import { ParallaxHeader } from "../../components/parallaxHeader"

import img1 from "./tahoe/1.jpg"
import img2 from "./tahoe/2.jpg"
import img3 from "./tahoe/3.jpg"

// import SEO from "../../components/seo"

import styled from "styled-components"

const Margin = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 5rem;
  max-width: 95rem;
`
const ParallaxContainer = styled.div`
  background: white;
`

const TextContainer = styled.div`
  flex: 1 1 250px;
  margin-right: 4rem;
`

const MarginTop = styled.div`
  margin-top: 5rem;
`

const images = [img1, img2, img3]

const slides = images.map(img => (
  <div>
    <img src={img} />
  </div>
))

const Product = ({ data, location }) => {
  const query = useStaticQuery(graphql`
    query ProductQuery {
      cover: file(relativePath: { eq: "product/tahoe/GebakkenTahoe1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Page location={location} title={"siteTitle"} transparentNav>
      <ParallaxHeader
        imgFluid={query.cover.childImageSharp.fluid}
        title="Productontwikkeling"
        imgMargin={"-5rem"}
      />
      {/* <PageLayout> */}
      <ParallaxContainer>
        <Margin>
          <RowFlex>
            <TextContainer>
              <p>
                Aardvark denkt graag mee in de ontwikkeling van nieuwe
                producten. Producten aan de hand van een nieuw idee, producten
                met een andere toepassing, een andere kleur, geur, smaak of
                vorm. Nieuwe producten die ontstaan uit de verwerking van
                reststromen. Van een idee via een projectplan naar een
                eindproduct.
              </p>
              <ul>
                <h4>Aardvark heeft ruime ervaring met:</h4>
                <li>
                  Het ontwikkelen van duurzame producten met behulp van duurzame
                  grondstoffen en processen
                </li>
                <li>
                  Het verwerken van reststromen (zowel plantaardig als dierlijk)
                  tot producten of concentraten
                </li>
                <li>
                  Het ontwikkelen van gezonde voedingsproducten door gebruik te
                  maken van gezonde oliën en bijvoorbeeld kruiden
                </li>
                <li>
                  Het vervangen van E-nummers door alternatieve grondstoffen
                </li>
                <li>
                  Het ontwikkelen van nieuwe smaken door gebruik te maken van
                  kruiden en natuurlijke processen
                </li>
                <li>
                  Het koppelen van functionele eigenschappen aan product
                  eigenschapen, waarbij gekeken wordt naar de effecten van
                  verwerkingsprocessen op:
                  <ul>
                    <li>eiwitten/koolhydraten/vetten </li>
                    <li>binding/textuur </li>
                    <li>smaak/geur </li>
                    <li>verkleuring</li>
                    <li>uitzakken, drijven en uit elkaar vallen </li>
                    <li>viscositeit, etc.</li>
                  </ul>
                </li>
              </ul>
            </TextContainer>
            <MarginTop>
              <SlideShow slides={slides} />
            </MarginTop>
          </RowFlex>
          {/* </PageLayout> */}
        </Margin>
      </ParallaxContainer>
    </Page>
  )
}

export default Product
