import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Page } from "../../components/page"
import { PageLayout } from "../../components/layout"
import Image from "gatsby-image"
import phoneIcon from "./phone-solid.svg"
import emailIcon from "./at-solid.svg"

// import SEO from "../components/seo"
import styled from "styled-components"

const Avatar = styled.div`
  flex: 0 1 180px;
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-column-gap: 3rem;
  margin: auto;
  width: fit-content;
  border: 1px solid gray;
  border-radius: 2rem;
  padding: 1.5rem 2rem 1rem 1rem;
  color: #2790b0;
`

const Fields = styled.ul`
  list-style: none;
  margin-left: 0;
  margin-top: 1.1rem;

  li {
    display: grid;
    grid-template-columns: 1.5rem 1fr;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;
  }
`

const Icon = styled.img`
  margin-bottom: 0;
`

const Contact = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      avatar: file(relativePath: { eq: "Marleen4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Page location={location} title={"siteTitle"}>
      <PageLayout>
        <h1>Contact</h1>
        Bent u op zoek naar een praktische en ervaren levensmiddelentechnoloog,
        neem dan contact op met Marleen Vrij via
        <Card>
          <Avatar>
            <Image
              fluid={data.avatar.childImageSharp.fluid}
              style={{ borderRadius: "500px" }}
            />
          </Avatar>
          <div>
            <Fields>
              <li>
                <Icon src={phoneIcon} />
                <h3>06-19187608</h3>
              </li>
              <li>
                <Icon src={emailIcon} />
                <h3>
                  <a href="mailto: info@aardvark.consulting">
                    Info@aardvark.consulting
                  </a>
                </h3>
              </li>
            </Fields>
            <h4>KvK-nummer: 5680 1289</h4>
          </div>
        </Card>
      </PageLayout>
    </Page>
  )
}

export default Contact
