import React, { useEffect, useState, useLayoutEffect, useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import styled from "styled-components"
import down from "./caret-down-solid.svg"

const LINK_WIDTH = 16

const NAV_HEIGHT = 5
const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${NAV_HEIGHT}rem;
  z-index: 9999;
  color: ${({ transparent, showNav }) =>
    transparent && !showNav ? "white" : "#2790b0"};
  font-weight: bold;
  display: grid;
  grid-template-columns: 15rem 1fr;
  background: ${({ transparent, showNav }) =>
    transparent && !showNav ? null : "white"};
  box-shadow: ${({ showNav }) =>
    showNav ? "0px -7px 14px -2px rgba(0, 0, 0, 0.75)" : null};

  a {
    z-index: 2;
  }

  transition: background 0.3s ease;
  filter: ${({ transparent, showNav }) =>
    transparent && !showNav ? "drop-shadow(0 1px 8px black)" : null};
`

const Links = styled.ul`
  display: flex;
  justify-content: space-around;
  width: 38rem;
  justify-self: end;
  align-self: end;
  padding-top: 1rem;
`

const Scrollable = styled.div`
  margin-top: ${NAV_HEIGHT}rem;
  // width: 100%;
  // height: 100%;
  // overflow: auto;
`

const NavLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  box-shadow: none;
  width: auto;
  color: inherit;
  width: ${LINK_WIDTH}rem;
  text-align: center;

  :hover {
    box-shadow: 0 1px 0 0 currentColor;
  }
`

const Dropdown = styled.div`
  padding-left: 1rem;
  // height: ${props => (props.show ? "auto" : 0)};
  cursor: default;
  position: relative;
  width: ${LINK_WIDTH}rem;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1rem;

  img {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  :hover {
    img {
      opacity: 1;
    }
  }

  ul {
    text-align: left;

    color: #2790b0;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 0px;
    overflow: hidden;
    background: ${({ transparent, showNav }) =>
      transparent && !showNav ? null : "white"};
    margin-top: 2rem;
    margin-left: -1rem;
    top: -5px;
    padding: 0 1rem;
    padding-top: 0.6rem;

    transition: height 0.3s ease;

    li {
      list-style: none;
      font-weight: normal;
    }
  }

  :hover ul {
    height: 9.7rem;
    padding-bottom: 1rem; // the last element in the list has a dropshadow that otherwise will overflow
    box-shadow: 0px 4px 7px -4px rgba(0, 0, 0, 0.75);

    background: ${({ transparent, showNav }) =>
      transparent && !showNav ? "#ffffffe0" : "white"};
  }
`

const Caret = styled.img`
  width: 0.8rem;
  padding-top: 3px;
`

export const Page = ({ location, title, children, transparentNav = false }) => {
  const [showNav, set_showNav] = useState(false)

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -50) set_showNav(true)
    else set_showNav(false)
  })

  const data = useStaticQuery(graphql`
    query PageQuery {
      logo: file(relativePath: { eq: "logo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <Nav showNav={showNav} transparent={transparentNav}>
        <Link to="/">
          <Image
            fluid={data.logo.childImageSharp.fluid}
            // style={{ height: "100%" }}
            // style={{ filter: "drop-shadow(0 1px 8px white)" }}
          />
        </Link>
        <Links>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/blog">Blog</NavLink>
          <NavLink to="/portfolio">Portfolio</NavLink>

          <Dropdown showNav={showNav} transparent={transparentNav}>
            <span>Diensten</span>
            <Caret src={down} />
            <ul>
              <li>
                <NavLink to="/recept">Recept</NavLink>
              </li>
              <li>
                <NavLink to="/onderzoek">Onderzoek</NavLink>
              </li>
              <li>
                <NavLink to="/product">Productontwikkeling</NavLink>
              </li>
              <li>
                <NavLink to="/training">Training</NavLink>
              </li>
            </ul>
          </Dropdown>

          <NavLink to="/contact">Contact</NavLink>
        </Links>
      </Nav>

      <Scrollable>{children}</Scrollable>
    </div>
  )
}

// export default Layout
