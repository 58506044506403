import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { Page } from "../../components/page"
import { PageLayoutParallax } from "../../components/layout"
import { ParallaxHeader } from "../../components/parallaxHeader"

import { Document, Page as PdfPage } from "react-pdf"
import styled from "styled-components"
import { Modal } from "../../components/modal"

import pdf0 from "./articles/Processing a neglegted ingredient.pdf"
import pdf1 from "./articles/Insectenmeel_Molenaar feb 2011_0001.pdf"
import pdf2 from "./articles/Grinding performance multicracker system Vrij 2012.pdf"
import pdf3 from "./articles/FST Vol 27 Issue 1 March 2013.pdf"
import pdf4 from "./articles/130201 Insects as alternative for fish feed.pdf"
import pdf5 from "./articles/WO2007100251A1.pdf"

const pdfs = [
  {
    file: pdf0,
    date: "2006",
    title: "Processing a neglected ingredient",
  },
  {
    file: pdf1,
    date: "2011",
    title: "Insectenmeel_Molenaar feb 2011_0001",
  },
  {
    file: pdf2,
    date: "2012",
    title: "Grinding performance multicracker system Vrij 2012",
  },
  {
    file: pdf3,
    date: "2013",
    title: "FST Vol 27 Issue 1 March 2013",
  },
  {
    file: pdf4,
    date: "2013",
    title: "Insects as alternative for fish feed",
  },
  {
    file: pdf5,
    date: "2007",
    title: "Patent coating animal feed",
  },
]

const PageOverflow = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const Portfolio = ({ data, location }) => {
  const [currentDocument, set_currentDocument] = useState(null)
  const [numPages, setNumPages] = useState(null)
  // const [pageNumber, setPageNumber] = useState(1)

  const query = useStaticQuery(graphql`
    query PortfolioQuery {
      cover: file(relativePath: { eq: "portfolio/Headerportfolio.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  return (
    <Page location={location} title={"siteTitle"} transparentNav>
      <ParallaxHeader
        imgFluid={query.cover.childImageSharp.fluid}
        title="Portfolio"
      />
      <PageLayoutParallax>
        {/* <h1>Portfolio</h1> */}

        <p>Een greep uit de afgeronde activiteiten in de afgelopen decennia:</p>

        <ul>
          <li>
            Ontwikkeling van de nu veelvuldig gebruikte “vacuüm” absorptie
            proces voor het verhogen van het vetgehalte (o.a. in visvoeders).{" "}
          </li>
          <li>
            Ontwikkeling zomer/winter vetsamenstelling voor producten, ter
            voorkoming van het zacht worden van producten in de zomer. Onderzoek
            en ontwikkelen van gezonde producten met oliën een hoog gehalte aan
            onverzadigde vetzuren, zoals ARA, EPA en DHA (uit o.a. algen,
            visproducten).
          </li>
          <li>
            Onderzoek en de ontwikkeling van producten met de effecten van
            kruiden op smaak, geur, houdbaarheid en gezondheid.
          </li>
          <li>
            Zelf ontwikkelde kwaliteitsanalyses (waterstabiliteit,
            verslepingstest, hardheidsmetingen, slijtvastheid, water- en
            vetabsorptie van grondstoffen)
          </li>
          <li>
            Onderzoek naar de “chewability” en binding van geëxtrudeerde
            producten met textuuranalyses
          </li>

          <li>
            Organiseren van workshops (practica) om mensen op te leiden op het
            gebied van technologie, grondstoffenkennis en applicaties.
          </li>
          <li>
            Geven van gastcolleges en begeleiden van scholieren/studenten
            (Basisschool, MBO, HBO-, en universitair niveau)
          </li>
          <li>
            Opschalen van een nieuw ontiwkkeld semi-moist diervoederproces op
            lab schaal naar een pilotfaciliteit, met HACCP en productie
            erkenning (NVWA).
          </li>
        </ul>

        <h2>Publicaties</h2>
        <ul>
          {pdfs.map(({ file, date, title }) => (
            <li onClick={() => set_currentDocument(file)}>
              <Link>{title}</Link> [{date}]
            </li>
          ))}
        </ul>

        {currentDocument && (
          <Modal close={() => set_currentDocument(null)}>
            <PageOverflow>
              <Document
                file={currentDocument}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <>
                    <PdfPage
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      // width="100%"
                      className="pdfViewer__page"
                    />
                    <hr />
                  </>
                ))}
              </Document>
            </PageOverflow>
            >
          </Modal>
        )}
      </PageLayoutParallax>
    </Page>
  )
}

export default Portfolio
