import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import Image from "gatsby-image"

import { Page } from "../../components/page"
import { PageLayoutParallax } from "../../components/layout"
import { ParallaxHeader } from "../../components/parallaxHeader"

// import SEO from "../../components/seo"

// TODO: handle scroll

const Onderzoek = ({ data, location }) => {
  const query = useStaticQuery(graphql`
    query OnderzoekQuery {
      boeken: file(relativePath: { eq: "onderzoek/boeken.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Page location={location} title={"siteTitle"} transparentNav>
      <ParallaxHeader
        imgFluid={query.boeken.childImageSharp.fluid}
        title="Ondersteuning bij (de voortgang van) onderzoek"
        imgMargin={"-5rem"}
      />

      <PageLayoutParallax>
        <p>
          Loopt uw onderzoek vast? Heeft u het druk en geen tijd voor het
          uitvoeren van bepaalde onderdelen van uw onderzoek? Aardvark kan
          bijspringen door te onderzoeken waar het bij een vastlopend onderzoek
          aan schort. Daarnaast kan Aardvark ondersteunen door het uitvoeren van
          productie werkzaamheden en/of het meewerken/leiding geven aan
          productie- of onderzoeksteams.
        </p>
        <ul>
          <h4>Aardvark helpt mee door:</h4>
          <li>
            Het bieden van extra “denk capaciteit” als er wat langer nagedacht
            moet worden over een specifiek probleem
          </li>
          <li>
            Het bieden van extra “handjes” in drukke tijden, proeven uitvoeren
            of begeleiden{" "}
          </li>
          <li>
            Het ontwikkelen van “onderzoekstools” voor eigenschappen van
            product/grondstoffen
          </li>
          <li>
            Het doen van literatuur onderzoek en rapportage over specifieke
            grondstof en product ontwikkelingen{" "}
          </li>
          <li>
            Het bieden van ondersteuning van het bijhouden van het
            kwaliteitssysteem
          </li>
          <li>
            Het zijn van een vraagbaak technologie, productspecificaties en
            productontwikkeling.
          </li>
          <li>
            Het delen van expertise op het gebied van het verbeteren van de
            houdbaarheid
          </li>
          <li>
            Het ontrafelen van producteigenschappen naar grondstof eigenschappen
            en omgekeerd.
          </li>
        </ul>
      </PageLayoutParallax>
    </Page>
  )
}

export default Onderzoek
