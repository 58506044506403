import React, { useState } from "react"
import styled from "styled-components"

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw:
    height: 100vh;
    background: rgba(0,0,0,0.7);
    color white;
    overflow: hidden;
    z-index:99999;
    //fancybox
    padding: 44px 44px 0;
    
`

export const Content = styled.div`
  height: calc(100% - 10px);
  margin-bottom: 10px;
`

export const BackContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem 1rem;
  button {
  }
  button:hover {
  }
`

export const Modal = ({ modalUrl, close, children }) => {
  return (
    <Container>
      <Content>{children}</Content>
      <BackContainer>
        <button onClick={close}>close</button>
      </BackContainer>
    </Container>
  )
}
