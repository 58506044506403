import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import { Page } from "../../components/page"
import {
  HomeLayout,
  RowFlex,
  ParagraphLayout as P,
} from "../../components/layout"

// import SEO from "../components/seo"
// import { rhythm } from "../utils/typography"

import Image from "gatsby-image"
import styled from "styled-components"

const ColoredBackgound = styled.div`
  background-color: #56a3af;
`

const BlogPreview = styled.section`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-column-gap: 1rem;

  padding: 1rem;
  background: hsl(188 49% 63% / 1);
  border-radius: 1rem;
  cursor: pointer;

  color: #333333;

  :hover {
    background: hsl(188 54% 73% / 1);
    h2 {
      text-decoration: underline;
    }
  }
`

const Card = styled.div`
  cursor: pointer;
  position: relative;

  flex: 1 1 180px;
  //   flex: 0 1 180px; /*  No stretching: */

  .card_img_wrapper {
    overflow: hidden;
    .gatsby-image-wrapper {
      transition: transform 0.3s ease;
    }
  }

  &:hover {
    .card_img_wrapper {
      .gatsby-image-wrapper {
        transform: scale(1.1);
      }
    }

    h3 {
      text-decoration: underline;
    }
  }
`

const CardTitle = styled.h3`
  width: 100%;
  text-align: center;
  // text-transform: uppercase;
  position: absolute;
  color: #5a2034;
  bottom: -2rem;
  // background: rgba(255, 255, 255, 0.7);
  text-shadow: 0px 0px 7px white;
  font-size: 1.2rem;
  padding: 0.5rem;
`

const Avatar = styled.div`
  flex: 0 1 180px;
`

const Subtitle = styled.h3`
  text-align: center;
  // color: #5a2034;
`

const Home = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allMdx(limit: 1, sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            excerpt
            frontmatter {
              title
              date
              path
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }

      recept: file(relativePath: { eq: "recept2.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ondersteuning: file(relativePath: { eq: "ondersteuning.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      training: file(relativePath: { eq: "training.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      product: file(relativePath: { eq: "productontwikkeling.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      avatar: file(relativePath: { eq: "Marleen4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  let latestPost = data.allMdx.edges[0].node || null

  return (
    <Page location={location} title={"siteTitle"}>
      <RowFlex
        style={{
          maxWidth: "72rem",
          margin: "auto",
          marginTop: "-1.5rem",
          marginBottom: "4rem",
        }}
      >
        <Card>
          <Link to="recept">
            <div className="card_img_wrapper">
              <Image fluid={data.recept.childImageSharp.fluid} />
            </div>
          </Link>
          <CardTitle> Recept formulering </CardTitle>
        </Card>

        <Card>
          <Link to="onderzoek">
            <div className="card_img_wrapper">
              <Image fluid={data.ondersteuning.childImageSharp.fluid} />
            </div>
          </Link>
          <CardTitle> Ondersteuning onderzoek</CardTitle>
        </Card>

        <Card>
          <Link to="product">
            <div className="card_img_wrapper">
              <Image fluid={data.product.childImageSharp.fluid} />
            </div>
          </Link>
          <CardTitle> Product-ontwikkeling </CardTitle>
        </Card>

        <Card>
          <Link to="training">
            <div className="card_img_wrapper">
              <Image fluid={data.training.childImageSharp.fluid} />
            </div>
          </Link>
          <CardTitle> Training </CardTitle>
        </Card>
      </RowFlex>

      <div>
        <ColoredBackgound>
          <P>
            <h1>Over aardvark</h1>
            <RowFlex>
              <Avatar>
                <Image
                  fluid={data.avatar.childImageSharp.fluid}
                  style={{ borderRadius: "500px" }}
                />
              </Avatar>

              <p style={{ flex: "1 1 50%", marginLeft: "2rem" }}>
                Een aardvarken (Engels: Aardvark) staat bekend om zijn speurzin
                en graafvermogen. Het zijn deze eigenschappen die Aardvark
                kenmerken. Aardvark wil de inhoud weten. Plakt het? Hoe ruikt of
                smaakt het? Welke kleur heeft het? Wat gebeurt er als je er in
                knijpt, het verwarmt, bevriest of maalt? Na een uitvoerige
                observatie wordt een indeling gemaakt naar eigenschappen.
                Aardvark deelt eerst in op functionaliteit en zoekt dan naar
                toepasbaarheid. Aardvark graaft in meerdere richtingen om de
                best passende oplossing te vinden. Aardvark werkt voor bedrijven
                en organisaties die producten ontwikkelen en produceren. De
                focus ligt daarbij op voeding voor mens en dier. Aardvark is
                creatief, praktisch, levert maatwerk en kan zelf testen
                uitvoeren. <br />
                Aardvark is een ontwikkel- en onderzoeksbedrijf op het gebied
                van hoogwaardige toepassingen in food (humane voeding) en feed
                (diervoeding)). Spil in het bedrijf Aardvark is Marleen Vrij.
              </p>
            </RowFlex>
          </P>
        </ColoredBackgound>
        <P>
          <h2>Over Marleen Vrij</h2>
          <p>
            Voordat ik Aardvark startte, heb ik als onderzoeker, hoofd
            productie/onderzoeksafdelingen bij diverse bedrijven gewerkt. Altijd
            op het gebied van product- en procesontwikkeling, waarbij de focus
            met name lag op het onderzoeken van functionele eigenschappen van
            grondstoffen: hoe zijn deze eigenschappen te gebruiken in de
            productontwikkeling of bij het ontstaan van productproblemen?
          </p>
          {/* <br /> */}
          <h4>Wat ik zoal gedaan heb: </h4>
          <ul style={{ padding: "0 3rem" }}>
            <li>studie Levensmiddelentechnologie (Universiteit Wageningen)</li>
            <li>
              kleine 20 jaar R&D en technologie ervaring bij een internationaal
              diervoederbedrijf
            </li>
            <li>
              vier jaar werkzaam bij een consultancy bedrijf op het gebied van
              product en procesontwikkeling
            </li>
            <li>
              acht jaar werkzaam bij een bedrijf dat alternatieve grondstoffen
              verwerkt tot voeding voor mens en dier
            </li>
            <li>
              verzorgen van diverse trainingen en gastcolleges bij bedrijven en
              instanties
            </li>
          </ul>
          <p>
            Ik ben een nieuwsgierige persoon die met plezier problemen op het
            gebied van levensmiddelentechniek analyseert en oplost. Daarbij
            staat altijd de vraag van de klant en de verbetering van het product
            voorop. Gezond voor mens en dier, gezond voor ieder bedrijf. Door
            mijn lange ervaring in het vakgebied heb ik een groot
            (kennis)netwerk en daarmee laagdrempelige toegang tot vakmanschap en
            capaciteit wanneer specifieke opdrachten daarom vragen.
          </p>
          <br />
          <h2>Werkwijze</h2>
          <p>
            Aardvark maakt voorafgaand aan een opdracht heldere afspraken, die
            schriftelijk worden bevestigd. Zowel langdurige inzet voor
            interimopdrachten op basis van vaste uurtarieven als fixed-price
            onderzoeksopdrachten zijn mogelijk. Voor langdurige opdrachten
            gelden lagere tarieven.
          </p>
        </P>

        <ColoredBackgound>
          <P>
            <h4>Bekijk hier mijn laatste blogpost:</h4>
            {latestPost && (
              <Link to={`blog/${latestPost.frontmatter.path}`}>
                <BlogPreview>
                  <Image
                    fluid={
                      latestPost.frontmatter.thumbnail.childImageSharp.fluid
                    }
                    style={{ borderRadius: "0.4rem", height: "7rem" }}
                  />
                  <div>
                    <h2>{latestPost.frontmatter.title}</h2>
                    <h5>
                      {new Date(
                        ...latestPost.frontmatter.date.split("-")
                      ).toLocaleString("nl-NL", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          latestPost.frontmatter.description ||
                          latestPost.excerpt,
                      }}
                    />
                  </div>
                </BlogPreview>
              </Link>
            )}
          </P>
        </ColoredBackgound>
      </div>
    </Page>
  )
}

export default Home
