import React, { useState } from "react"
import styled from "styled-components"

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { useInterval } from "../hooks"

let Container = styled.div`
  height: 250px;
  width: 20rem;
  /* width: 18rem; */
  display: grid;
  grid-template-rows: 1fr auto;
`

let Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 3rem;
  background: ${props => (props.isSelected ? "black" : "gray")};
  margin: 0.5rem;
  transform: ${props => (props.isSelected ? "scale(1.2)" : "")};

  cursor: pointer;

  :hover {
    transform: ${props => (!props.isSelected ? "scale(1.2)" : "")};
  }
`

let DotWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

let ImageContainer = styled.div``

export const SlideShow = ({ slides }) => {
  let [currentSlide, set_currentSlide] = useState(0)

  //update chart
  useInterval(() => {
    if (currentSlide === slides.length - 1) {
      set_currentSlide(0)
    } else {
      set_currentSlide(currentSlide + 1)
    }
  }, 1000 * 8.5)

  return (
    <Container>
      <ImageContainer>
        {slides.length > 0 && slides[currentSlide]}
      </ImageContainer>
      <DotWrapper>
        {slides.map((slide, i) => {
          return (
            <Dot
              isSelected={i === currentSlide}
              onClick={() => set_currentSlide(i)}
            />
          )
        })}
      </DotWrapper>
    </Container>
  )
}
