import React from "react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { Page } from "../components/page"
import { PageLayout } from "../components/layout"

import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import styled from "styled-components"

const Section = styled.section`
  display: grid;
  grid-template-columns: 10rem 1fr;
  grid-column-gap: 1rem;
`

const Article = styled.article`
  margin: 2rem 0;
`

const BlogIndex = ({ data, location }) => {
  const posts = data.allMdx.edges

  return (
    <Page location={location} title={"siteTitle"}>
      <PageLayout>
        <SEO title="All posts" />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.slug
          return (
            <Article key={node.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link
                    style={{ boxShadow: `none` }}
                    to={node.frontmatter.path}
                  >
                    {title}
                  </Link>
                </h3>
                <h5>
                  {new Date(...node.frontmatter.date.split("-")).toLocaleString(
                    "nl-NL",
                    {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </h5>
              </header>
              <Section>
                <Link style={{ boxShadow: `none` }} to={node.frontmatter.path}>
                  <Image
                    fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
                    style={{ borderRadius: "0.4rem", height: "7rem" }}
                  />
                </Link>

                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </Section>
            </Article>
          )
        })}
      </PageLayout>
    </Page>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          id
          frontmatter {
            title
            date
            path
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt
          slug
        }
      }
    }
  }
`
