import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import { Page } from "../../components/page"
import { PageLayoutParallax } from "../../components/layout"
import { ParallaxHeader } from "../../components/parallaxHeader"

// import SEO from "../../components/seo"

import styled from "styled-components"

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const Card = styled.div`
  //   cursor: pointer;
  flex: 1 1 180px;
  align-content: center;
`

const Recept = ({ data, location }) => {
  const query = useStaticQuery(graphql`
    query ReceptQuery {
      cover: file(relativePath: { eq: "recept/Mengsel-grondstoffen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bord: file(relativePath: { eq: "recept/Bord.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      etiket: file(relativePath: { eq: "recept/Etiket.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  console.log("query", query)

  return (
    <Page location={location} title={"siteTitle"} transparentNav>
      <ParallaxHeader
        imgFluid={query.cover.childImageSharp.fluid}
        title="Recept (her)formulering"
      />
      <PageLayoutParallax>
        <p>
          Vaak kan een enorme productverbetering plaatsvinden wanneer een recept
          opnieuw geformuleerd wordt. Bijvoorbeeld bij een optimalisatie naar
          een gezonder product, door het toevoegen van meer vezels, onverzadigde
          vetten, kruiden of andere zoetstoffen. Een nieuw recept kan een
          product behalve beter ook (veel) goedkoper maken door innovatieve
          procestechnologie slimmer in te zetten.
        </p>
        <br />
        <ul>
          <h4>Aardvark voegt waarde toe door:</h4>
          <li>
            Het berekenen van nutriënten (eiwitten, koolhydraten, vetten,
            vezels, etc.)
          </li>
          <li>Het formuleren van een gezonde productsamenstelling</li>
          <li>Het verlagen van de prijs door herformulering van recepten</li>
          <li>
            Het berekenen van de verhouding tussen verzadigde en onverzadigde
            vetten
          </li>
        </ul>
        <Wrap>
          <Card>
            <Image fluid={query.bord.childImageSharp.fluid} />
          </Card>
          <Card>
            <Image fluid={query.etiket.childImageSharp.fluid} />
          </Card>
        </Wrap>
      </PageLayoutParallax>
    </Page>
  )
}

export default Recept
