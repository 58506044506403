import styled from "styled-components"
import React, { useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const Background = styled.div`
  position: absolute;
  width: 100%;
  //   z-index: 1;
  top: 0;
  height: 18rem;
  overflow: hidden;

  //
`

const Cover = styled.div`
  text-align: center;
  height: 15rem;
  //   overflow: hidden;
  position: relative;

  //

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h1 {
    color: snow;
    filter: drop-shadow(0 0 0.75rem black);
    text-shadow: 0px 0px 3px black;
  }
`

const ImageContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -2;
  // height: 18rem;
  overflow: hidden;
`

export const ParallaxHeader = ({ imgFluid, title, imgMargin }) => {
  const [scroll, set_scroll] = useState(0)
  const imgRef = useRef(null)
  useScrollPosition(({ prevPos, currPos }) => {
    set_scroll(currPos.y)
    // console.log("scrolll", currPos)
  })
  return (
    <>
      <Background>
        <ImageContainer
          style={
            {
              // top: `${scroll * 0.3 - 30}px`,
            }
          }
        >
          <Image
            ref={imgRef}
            fluid={imgFluid}
            style={{
              top: `${Math.floor(scroll * 0.3) - 30}px`,
              // transform: `translate(0,${Math.floor(scroll * 0.3) - 30}px)`,
              // position: "absolute",
              //   objectFit: "cover",
              width: "100%",
              marginTop: imgMargin,
            }}
          />
        </ImageContainer>
      </Background>
      <Cover>
        <div>
          <h1>{title}</h1>
        </div>
      </Cover>
    </>
  )
}
