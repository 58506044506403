import React from "react"
import { graphql } from "gatsby"

import { Page } from "../components/page"
import { PageLayout } from "../components/layout"

import SEO from "../components/seo"

const Diensten = ({ data, location }) => {
  return (
    <Page location={location} title={"siteTitle"}>
      <PageLayout>
        <h1>Diensten</h1>
      </PageLayout>
    </Page>
  )
}

export default Diensten
