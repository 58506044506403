import React from "react"

import { rhythm, scale } from "../utils/typography"
import styled from "styled-components"

export const PageLayout = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(30)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`

// export const HomeLayout = styled.div`
//   margin-left: auto;
//   margin-right: auto;
// `

export const ParagraphLayout = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(30)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`

const ParallaxContainer = styled.div`
  background: white;
`

export const PageLayoutParallax = ({ children }) => {
  return (
    <ParallaxContainer>
      <PageLayout>{children}</PageLayout>
    </ParallaxContainer>
  )
}

export const RowFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

// export const PageLayout = props => (
//   <Container
//   >
//     <main>{children}</main>
//   </Container>
// )
